<template>
  <DefaultLayout>
    <FloatingBox class="sm:w-128">
      <Alert :alert="alert" class="mb-4">

      </Alert>

      <Button class="w-full bg-brand text-xl ml-0 m-1 mb-0" anchor href="https://www.islandluck.com/Home.aspx" target="_top">
        {{$t('confirmation.close')}}
      </Button>

    </FloatingBox>

  </DefaultLayout>
</template>

<script>
import Alert from '../components/Alert.vue'
import FloatingBox from '../components/FloatingBox.vue'
import DefaultLayout from '../layouts/Default.vue'
import Button from '../components/Button.vue';

export default {
  components: {
    DefaultLayout, 
    FloatingBox, 
    Alert, 
    Button
  },
  data() {
    return {
      alert: {
        type: 'success',
        title: this.$t('confirmation.title'),
        text: this.$t('confirmation.text'),
      }
    }
  }
}
</script>